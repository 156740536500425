import React from 'react';
import PropTypes from 'prop-types';

const LinkList = ({ links }) => (
  <ul>
    {links.length > 0 &&
      links.map(link => (
        <li
          className="py-4 border-b border-gray-50 last:border-b-0"
          key={link.id}
        >
          {link.current ? (
            <span className="font-bold text-primary-700">{link.title}</span>
          ) : (
            <a className="text-black" href={link.url}>
              {link.title}
            </a>
          )}
        </li>
      ))}
  </ul>
);

LinkList.propTypes = {
  links: PropTypes.array.isRequired,
};

export default LinkList;
