import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useUserIPGrabber } from '@cleverrealestate/clever-helpers';
import useGlobalTOCHandler from '../utils/useGlobalTOCHandler';
import renderContent from '../utils/renderContent';
import Layout from '../components/layout/Layout';
import SEO from '../components/meta/SEO';
import PageContainer from '../components/layout/PageContainer';
import CtaSection from '../components/organisms/CtaSection';
import { TipsArticleSideBar } from '../components/organisms/ArticleSideBar';
import Icon from '../components/atoms/Icon';

const ContentPageTemplate = ({
  pageContext,
  renderLayout,
  dummySidebarLinks,
}) => {
  useUserIPGrabber();
  useGlobalTOCHandler();

  const {
    // Display Values
    authors,
    cosmicEditPath,
    hideAuthors,
    hideShareButton,
    // Content Values
    title,
    renderContext,
    matchingCategories,
    // SEO Values
    articleImage,
    canonicalSlug,
    createdAtIso,
    extraMeta,
    metaDescription,
    metaTitle,
    publishedAtFormatted,
    publishedAtIso,
    next,
    noIndex,
    prev,
    slug,
    structuredData,
    contentTopic,
    contentType,
  } = pageContext;

  const publishDateMeta = [
    {
      name: 'article:published_time',
      content: createdAtIso,
    },
    {
      name: 'article:published_time',
      content: publishedAtIso,
    },
    {
      name: 'og:updated_time',
      content: publishedAtIso,
    },
  ];

  const pageContent = () => (
    <PageContainer
      authors={authors}
      cosmicEditPath={cosmicEditPath}
      hideAuthors={hideAuthors}
      hideShareButton={hideShareButton}
      publishDate={publishedAtFormatted}
      slug={slug}
      title={title}
      containerSize="lg"
    >
      <div className="grid grid-cols-1 gap-16 lg:gap-32 lg:grid-cols-4">
        <div className="rich-text lg:col-span-3">
          {renderContent(renderContext.html, renderContext.context)}

          <CtaSection className="my-6" link={process.env.MAIN_CTA_LINK} />

          <p className="text-gray-600">
            Posted in{' '}
            {matchingCategories.map(({ node }, index) => (
              <Fragment key={node.slug}>
                <a
                  className="text-gray-600 underline"
                  href={`/tips/categories/${node.slug}/`}
                  key={node.slug}
                >
                  {node.title}
                </a>

                {index < matchingCategories.length - 1 ? ', ' : ''}
              </Fragment>
            ))}
          </p>

          <div className="flex justify-between gap-6 text-sm md:text-base">
            {prev && (
              <a href={prev.slug}>
                <span className="block mb-2 font-bold text-black">
                  Previous
                </span>
                <span className="flex gap-2">
                  <Icon className="w-3" type="arrowLeft" />
                  <span className="flex-1">{prev.title}</span>
                </span>
              </a>
            )}

            {next && (
              <a className="text-right" href={next.slug}>
                <span className="block mb-2 font-bold text-black">Next</span>
                <span className="flex gap-2">
                  <span className="flex-1">{next.title}</span>
                  <Icon className="w-3" type="arrowRight" />
                </span>
              </a>
            )}
          </div>
        </div>

        <TipsArticleSideBar
          currentPage={slug}
          dummySidebarLinks={dummySidebarLinks}
        />
      </div>
    </PageContainer>
  );

  return renderLayout ? (
    <Layout>
      <SEO
        pathname={slug}
        title={metaTitle}
        description={metaDescription}
        canonical={canonicalSlug || slug}
        publishDateMeta={publishDateMeta}
        structuredData={structuredData}
        noIndex={noIndex}
        image={articleImage.url}
        imageHeight={articleImage.height}
        imageWidth={articleImage.width}
        meta={extraMeta}
        contentTopic={contentTopic}
        contentType={contentType}
      />

      {pageContent()}
    </Layout>
  ) : (
    pageContent()
  );
};

ContentPageTemplate.propTypes = {
  pageContext: PropTypes.any,
  renderLayout: PropTypes.bool,
  dummySidebarLinks: PropTypes.array,
};

ContentPageTemplate.defaultProps = {
  pageContext: {},
  renderLayout: true,
  dummySidebarLinks: null,
};

export default ContentPageTemplate;
