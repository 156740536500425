import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import LinkList from '../../molecules/LinkList';

const TipsArticleSideBar = ({ currentPage, dummySidebarLinks }) => {
  // TODO: Make sure query results are sorted correctly
  const { allCosmicjsContentTags, allCosmicjsContentPages } =
    useStaticQuery(graphql`
      query {
        allCosmicjsContentTags(
          limit: 20
          filter: {
            articles: {
              articles: {
                elemMatch: {
                  metadata: { path_directory: { slug: { in: "tips" } } }
                }
              }
            }
          }
          sort: { order: DESC, fields: articles___count }
        ) {
          edges {
            node {
              id
              title
              slug
              articles {
                count
              }
            }
          }
        }
        allCosmicjsContentPages(
          limit: 5
          sort: { fields: published_at, order: DESC }
          filter: { metadata: { path_directory: { slug: { in: "tips" } } } }
        ) {
          edges {
            node {
              id
              title
              slug
              metadata {
                slug_override
                path_directory {
                  slug
                }
              }
            }
          }
        }
      }
    `);

  return (
    <div className="flex flex-col gap-10 text-base">
      <div>
        <h4 className="mb-4">Blog Topics</h4>
        <LinkList
          links={
            dummySidebarLinks ||
            allCosmicjsContentTags.edges.map(({ node }) => ({
              id: node.id,
              title: `${node.title} (${node.articles.count})`,
              url: `/tips/categories/${node.slug}/`,
              current: currentPage?.includes(node.slug),
            }))
          }
        />
      </div>

      <div>
        <h4 className="mb-4">Recent Posts</h4>
        <LinkList
          links={
            dummySidebarLinks ||
            allCosmicjsContentPages.edges.map(({ node }) => ({
              id: node.id,
              title: node.title,
              url: `/${node.metadata.path_directory.slug}/${
                node.metadata.slug_override || node.slug
              }/`,
              current: currentPage?.includes(node.slug),
            }))
          }
        />
      </div>
    </div>
  );
};

TipsArticleSideBar.defaultProps = {
  currentPage: null,
  dummySidebarLinks: null,
};

TipsArticleSideBar.propTypes = {
  currentPage: PropTypes.string,
  dummySidebarLinks: PropTypes.array,
};

export default TipsArticleSideBar;
