import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import LinkList from '../../molecules/LinkList';

const ArticleSideBar = ({ currentPage }) => {
  // TODO: Make sure query results are sorted correctly
  const { allCosmicjsContentTags } =
    useStaticQuery(graphql`
      query {
        allCosmicjsContentTags(
          limit: 20
          filter: {articles: {articles: {elemMatch: {metadata: {path_directory: {slug: {in: "resources"}}}}}}}
          sort: {order: DESC, fields: articles___count}
        ) {
          edges {
            node {
              id
              title
              slug
              articles {
                count
              }
            }
          }
        }
      }
    `);

  return (
    <div className="flex flex-col gap-10 text-base">
      <div>
        <h4 className="mb-4">Learning Center Topics</h4>
        <LinkList
          links={allCosmicjsContentTags.edges.map(({ node }) => ({
            id: node.id,
            title: `${node.title} (${node.articles.count})`,
            url: `/resources/topic/${node.slug}/`,
            current: currentPage?.includes(node.slug),
          }))}
        />
      </div>
    </div>
  );
};

ArticleSideBar.defaultProps = {
  currentPage: null,
};

ArticleSideBar.propTypes = {
  currentPage: PropTypes.string,
};

export default ArticleSideBar;
