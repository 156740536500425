/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const CtaSection = ({ className, link }) => (
  <section
    className={classNames(
      'p-6 text-center rounded bg-primary-100 md:text-left md:px-12 md:py-10',
      className,
    )}
  >
    <h2 className="mb-2">Interested in buying or selling?</h2>
    <p className="mb-6">
      We&apos;ve improved the traditional real estate model with modern
      technology to cut costs, not quality.
    </p>

    <a
      className="rounded-full button button--primary"
      href={link}
      target="_blank"
      rel="noopener nofollow"
    >
      Get started today
    </a>
  </section>
);

CtaSection.defaultProps = {
  className: null,
};

CtaSection.propTypes = {
  className: PropTypes.string,
  link: PropTypes.string.isRequired,
};

export default CtaSection;
